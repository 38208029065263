import { Field, Form, Formik } from "formik";
import { Link } from "gatsby";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import ServiceAreas from "../../components/images/service-areas";
import GoogleReviews from "../../components/sections/google-reviews";
import Seo from "../../components/seo";
import CartContext from "../../context/cart-context";
import { extendedZipCodes, zipCodes } from "../../data/zip-codes";

function ServiceAreasPageTemplate(props) {
  const cartContext = useContext(CartContext);
  return (
    <>
      <Seo
        title={props.currentPage.seo.title}
        description={props.currentPage.seo.metaDesc}
      />
      <Helmet>
        <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Service",
          "serviceType": "Window Well Covers",
          "areaServed": [
            {
              "@type": "City",
              "name": "Denver",
              "sameAs": "https://en.wikipedia.org/wiki/Denver"
            },
            {
                "@type": "City",
                "name": "Aurora",
                "sameAs": "https://en.wikipedia.org/wiki/Aurora,_Colorado"
            },
            {
                "@type": "City",
                "name": "Boulder",
                "sameAs": "https://en.wikipedia.org/wiki/Boulder,_Colorado"
            },
            {
                "@type": "City",
                "name": "Fort Collins",
                "sameAs": "https://en.wikipedia.org/wiki/Fort_Collins,_Colorado"
            },
            {
                "@type": "City",
                "name": "Loveland",
                "sameAs": "https://en.wikipedia.org/wiki/Loveland,_Colorado"
            },
            {
                "@type": "City",
                "name": "Greeley",
                "sameAs": "https://en.wikipedia.org/wiki/Greeley,_Colorado"
            },
            {
                "@type": "City",
                "name": "Colorado Springs",
                "sameAs": "https://en.wikipedia.org/wiki/Colorado_Springs,_Colorado"
            },
            {
                "@type": "City",
                "name": "Lakewood Thornton",
                "sameAs": "https://en.wikipedia.org/wiki/Lakewood_Thornton,_Colorado"
            },
            {
                "@type": "City",
                "name": "Arvada",
                "sameAs": "https://en.wikipedia.org/wiki/Arvada,_Colorado"
            },
            {
                "@type": "City",
                "name": "Westminster",
                "sameAs": "https://en.wikipedia.org/wiki/Westminster,_Colorado"
            },
            {
                "@type": "City",
                "name": "Centennial",
                "sameAs": "https://en.wikipedia.org/wiki/Centennial,_Colorado"
            },
            {
                "@type": "City",
                "name": "Castle Rock",
                "sameAs": "https://en.wikipedia.org/wiki/Castle_Rock,_Colorado"
            },
            {
                "@type": "City",
                "name": "Longmont",
                "sameAs": "https://en.wikipedia.org/wiki/Longmont,_Colorado"
            },
            {
                "@type": "City",
                "name": "Parker",
                "sameAs": "https://en.wikipedia.org/wiki/Parker,_Colorado"
            },
            {
                "@type": "City",
                "name": "Brighton",
                "sameAs": "https://en.wikipedia.org/wiki/Brighton,_Colorado"
            },
            {
                "@type": "City",
                "name": "Littleton",
                "sameAs": "https://en.wikipedia.org/wiki/Littleton,_Colorado"
            },
            {
                "@type": "City",
                "name": "Johnstown",
                "sameAs": "https://en.wikipedia.org/wiki/Johnstown,_Colorado"
            },
            {
                "@type": "City",
                "name": "Evans",
                "sameAs": "https://en.wikipedia.org/wiki/Evans,_Colorado"
            },
            {
                "@type": "City",
                "name": "Hudson",
                "sameAs": "https://en.wikipedia.org/wiki/Hudson,_Colorado"
            },
            {
                "@type": "City",
                "name": "Fountain",
                "sameAs": "https://en.wikipedia.org/wiki/Fountain,_Colorado"
            },
            {
                "@type": "City",
                "name": "Peyton",
                "sameAs": "https://en.wikipedia.org/wiki/Peyton,_Colorado"
            },
            {
                "@type": "City",
                "name": "Security-Widefield",
                "sameAs": "https://en.wikipedia.org/wiki/Security-Widefield,_Colorado"
            },
            {
                "@type": "City",
                "name": "Highlands Ranch",
                "sameAs": "https://en.wikipedia.org/wiki/Highlands_Ranch,_Colorado"
            },
            {
                "@type": "City",
                "name": "Englewood",
                "sameAs": "https://en.wikipedia.org/wiki/Englewood,_Colorado"
            },
            {
                "@type": "City",
                "name": "Broomfield",
                "sameAs": "https://en.wikipedia.org/wiki/Broomfield,_Colorado"
            },
            {
                "@type": "City",
                "name": "Elizabeth",
                "sameAs": "https://en.wikipedia.org/wiki/Elizabeth,_Colorado"
            },
            {
                "@type": "City",
                "name": "Elbert",
                "sameAs": "https://en.wikipedia.org/wiki/Elbert,_Colorado"
            },
            {
                "@type": "City",
                "name": "Larkspur",
                "sameAs": "https://en.wikipedia.org/wiki/Larkspur,_Colorado"
            },
            {
                "@type": "City",
                "name": "Monument",
                "sameAs": "https://en.wikipedia.org/wiki/Monument,_Colorado"
            }
          ]
        }
      `}</script>
      </Helmet>
      <div className="max-w-2xl m-auto flex flex-wrap pt-8 pb-16 px-4 text-center text-grey-darker">
        <h1
          className="m-auto"
          dangerouslySetInnerHTML={{
            __html: props.currentPage.pages.h1Title
              ? props.currentPage.pages.h1Title
              : props.currentPage.title,
          }}
        />
      </div>
      <div className="max-w-2xl m-auto flex flex-wrap pb-16">
        <div className="w-full lg:w-1/2 pr-8">
          <ServiceAreas className="w-full max-w-full mb-4 rounded-md shadow mr-8" />
          <p className="leading-loose p-4 lg:p-0">
            The area in YELLOW is our regular service area that we travel to at
            no charge.
            <br />
            The area in RED we can service, but there is a{" "}
            <strong>${process.env.EXTENDED_AREA_FEE} travel charge.</strong>
          </p>
        </div>
        <div className="w-full lg:w-1/2 p-4 lg:px-8 lg:py-0 mb-8 text-grey-darker max-w-2xl wp-content">
          <Formik
            initialValues={{
              shippingZip: cartContext.checkoutInfo.shippingZip
                ? cartContext.checkoutInfo.shippingZip
                : "",
            }}
            validate={(values) => {
              let errors = {};

              cartContext.setCheckoutInfo({
                ...cartContext.checkoutInfo,
                shippingZip: values.shippingZip,
              });

              if (!values.shippingZip) {
                errors.shippingZip = "Required";
              } else if (values.shippingZip.length !== 5) {
                errors.shippingZip = "Please enter a valid zip code";
              } else if (
                zipCodes.indexOf(parseInt(values.shippingZip)) < 0 &&
                extendedZipCodes.indexOf(parseInt(values.shippingZip)) < 0
              ) {
                errors.shippingZip =
                  "We do not currently service that area. Please call us for more information.";
              }

              cartContext.setCheckoutInfo({
                ...cartContext.checkoutInfo,
                ...values,
              });

              return errors;
            }}
            onSubmit={(values) => {
              console.info(values);
            }}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="mb-12 pb-6 border-b border-grey-lighter">
                <div className="mb-6">
                  <label htmlFor="shippingZip" className="block mb-2 text-2xl">
                    <strong>Are you in our service area?</strong>
                  </label>
                  <Field
                    type="text"
                    name="shippingZip"
                    placeholder="Enter your zip code"
                    className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                  />
                  {errors.shippingZip && touched.shippingZip && (
                    <div className="mt-2 text-red italic">
                      {errors.shippingZip}
                    </div>
                  )}
                  <p
                    className={`${
                      cartContext.requiresExtendedServiceFee
                        ? "mt-2 text-orange-dark"
                        : "hidden"
                    }`}
                  >
                    The zip code you have entered is in our extended service
                    area and requires an additional charge of $
                    {process.env.EXTENDED_AREA_FEE}.
                    <br />
                    Please be aware that turnaround times for your area are
                    extended due to your location.
                  </p>
                  <p
                    className={`${
                      !errors.shippingZip &&
                      values.shippingZip.length === 5 &&
                      !cartContext.requiresExtendedServiceFee
                        ? "mt-2 text-yellow-darker"
                        : "hidden "
                    }`}
                  >
                    You are in our regular service area that we travel to at no
                    charge.
                  </p>
                  <p
                    className={`${
                      values.shippingZip.length === 5 && errors.shippingZip
                        ? "mt-2 text-red-dark"
                        : "hidden"
                    }`}
                  >
                    We do not currently service that area. Please{" "}
                    <Link to="/contact-us/" className="text-red-dark">
                      call us
                    </Link>{" "}
                    for more information.
                  </p>
                </div>
              </Form>
            )}
          </Formik>
          <div
            dangerouslySetInnerHTML={{
              __html: props.currentPage.content,
            }}
            className="wp-content"
          />
        </div>
      </div>
      <GoogleReviews />
    </>
  );
}
export default ServiceAreasPageTemplate;
