import React from "react";
import PolyDown from "../images/poly-down";
import KidOnCover from "../images/kid-on-cover";

function HighQuality() {
  return (
    <div className="w-full md:w-1/2">
      <div className="mb-8">
        <PolyDown className="mb-8 h-64" />
      </div>
      <div>
        <KidOnCover className="h-64" />
      </div>
    </div>
  );
}

export default HighQuality;
