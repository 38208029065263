import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import GoogleReviews from "../../components/sections/google-reviews";
import Seo from "../../components/seo";

function BlogPageTemplate(props) {
  return (
    <StaticQuery
      query={blogQuery}
      render={(data) => {
        const { edges } = data.allWpPost;
        return (
          <>
            <Seo
              title={props.currentPage.seo.title}
              description={props.currentPage.seo.metaDesc}
            />
            <div className="max-w-4xl m-auto flex flex-wrap pt-8 pb-4 px-4 text-grey-darkest lg:pt-16">
              <h1
                dangerouslySetInnerHTML={{
                  __html: props.currentPage.pages.h1Title
                    ? props.currentPage.pages.h1Title
                    : props.currentPage.title,
                }}
              />
            </div>
            <div className="max-w-4xl mx-auto px-4">
              <div className="max-w-lg pb-8">
                <div
                  className="w-full mb-8 text-grey-darker prose prose-lg"
                  dangerouslySetInnerHTML={{
                    __html: props.currentPage.content,
                  }}
                />
              </div>
            </div>
            <div className="max-w-4xl mx-auto flex flex-wrap pb-16 px-4">
              <h2 className="w-full text-2xl font-bold text-grey-darkest pb-8">
                Recent Posts
              </h2>
              <div className="w-full p-4 md:grid md:grid-cols-2 gap-8 md:p-0">
                {edges.map((data, index) => {
                  return (
                    <Link
                      className="block relative px-4 py-6 rounded-xl cursor-pointer text-black w-full group border border-grey-light"
                      key={index}
                      to={`/post/${data.node.slug}/`}
                    >
                      <div className="absolute top-0 left-0 w-full h-full rounded-xl">
                        <img
                          src={data.node.featuredImage.node.localFile.publicURL}
                          alt={data.node.featuredImage.node.title}
                          className="w-full h-full object-cover rounded-xl"
                        />
                      </div>
                      <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-80 group-hover:bg-opacity-90 transition-all rounded-xl" />
                      <div className="relative z-50 prose">
                        <h2>
                          <span className="group-hover:underline">
                            {data.node.title}
                          </span>
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.node.excerpt,
                          }}
                        />
                        {/* <p className="italic text-sm">{data.node.date}</p> */}
                        <p className="pt-2">
                          <span className="underline">Read More</span>
                        </p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            <GoogleReviews />
          </>
        );
      }}
    />
  );
}

const blogQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          date(formatString: "MMM DD, YYYY")
          title
          slug
          content
          excerpt
          featuredImage {
            node {
              title
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPageTemplate;
