import classNames from "classnames";
import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import AccessoryLadder from "../../components/images/accessory-ladder";
import AccessoryLock from "../../components/images/accessory-lock";
import GoogleReviews from "../../components/sections/google-reviews";
import Seo from "../../components/seo";
import { Price } from "../../utilities/price";
import { styles } from "../../utilities/styles";

function AccessoriesPageTemplate(props) {
  const data = useStaticQuery(
    graphql`
      query GetAccessoryProductsIndexPageQuery {
        allWpProduct {
          edges {
            node {
              id
              title
              slug
              status
              product {
                basePrice
                salePrice
                sku
                taxable
                category
                cartCalloutText
                type
                description
              }
            }
          }
        }
      }
    `
  );

  const accessories = (data.allWpProduct.edges || [])
    .filter(({ node }) =>
      node.product.category.find((cat) => cat === "accessories")
    )
    .map(({ node }) => node);

  const ezLiftAccessory = accessories.find((node) =>
    node.title.toLowerCase().includes("ez lift")
  );
  const ladderAccessory = accessories.find((node) =>
    node.title.toLowerCase().includes("ladder")
  );
  const lockAccessory = accessories.find((node) =>
    node.title.toLowerCase().includes("lock")
  );

  return (
    <>
      <Seo
        title={props.currentPage.seo.title}
        description={props.currentPage.seo.metaDesc}
      />
      <div>
        <div className="flex flex-wrap default-page pt-8 pb-16">
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto mb-8 text-grey-darker text-center">
            <h1
              dangerouslySetInnerHTML={{
                __html: props.currentPage.pages.h1Title
                  ? props.currentPage.pages.h1Title
                  : props.currentPage.title,
              }}
            />
            <div>
              <p>
                Getting the right window well accessories will ensure that you
                have easy access in and out of your window wells when needed and
                that they are locked down the rest of the time.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center bg-grey-lighter text-grey-darkest">
          <div className="w-full md:w-1/2 py-16 px-4 md:px-16 leading-tighter">
            <h2 className="mb-4 underline">
              <Link to="/ez-lift-systems/" className="text-grey-darkest">
                EZ Lift Systems
              </Link>
            </h2>
            <h3 className="mb-2">
              <Price
                value={
                  ezLiftAccessory?.product?.salePrice ||
                  ezLiftAccessory?.product?.basePrice ||
                  209
                }
              />
            </h3>
            <p>
              The EZ lift system provides a simple solution for opening and
              closing your covers. Only takes 2-3 lbs of force lifts the cover
              and our hydraulic arms at each end will hold it upright for as
              long as you need. This makes access in and out easy. When you want
              your window well covered again just gently push it back into
              place. The EZ lift system can be added to your order and installed
              with new covers. The EZ lift works smoothly with all other{" "}
              {process.env.COMPANY_NAME_SHORT} accessories including locks and
              ladders. Our system will only work with covers we have built.
            </p>
            <ul className="py-4">
              <li>
                Easy to open from the inside to allow for escape in an
                emergency.
              </li>
              <li>
                Simple enough to open that a child can do it. Only 2-3 lbs of
                pressure needed.
              </li>
            </ul>
            <Link
              to="/ez-lift-systems/"
              className={classNames(
                "block md:inline-block text-sm px-4 py-3 no-underline rounded uppercase shadow",
                styles.button.default
              )}
            >
              Buy your EZ Lift System
            </Link>
          </div>
          <div className="w-full md:w-1/2">
            <iframe
              src="https://www.youtube.com/embed/d915RDLOwco"
              width="100%"
              height="500"
              frameBorder="0"
              allowFullScreen="allowfullscreen"
              title={`${process.env.COMPANY_NAME_SHORT} EZ Lift System`}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center flex-row-reverse bg-white text-grey-darkest">
          <div className="w-full md:w-1/2 py-16 px-4 md:px-16 leading-tighter">
            <h2 className="mb-4 underline">
              <Link to="/window-well-ladders/" className="text-grey-darkest">
                Window Well Ladders
              </Link>
            </h2>
            <h3 className="mb-2">
              <Price
                value={
                  ladderAccessory?.product?.salePrice ||
                  ladderAccessory?.product?.basePrice ||
                  85
                }
              />
            </h3>
            <p>
              All of our window well accessories are designed to the highest
              standards to last as long as your covers do. If you ever need to
              get into or out of your window wells, then a ladder is the best
              way to go. You don’t always need a quick exit or entry, but you’ll
              be glad you have a window well ladder when you do need to. Plus,
              it’s much safer to use than trying to maneuver in and out without
              one. In an emergency, it creates an easy way for kids to get out
              as well.
            </p>
            <p className="pt-4">Available in:</p>
            <ul>
              <li>3-step</li>
              <li>4-step</li>
              <li>5-step</li>
            </ul>
            <Link
              to="/window-well-ladders/"
              className={classNames(
                "block md:inline-block text-sm px-4 py-3 no-underline rounded uppercase shadow",
                styles.button.default
              )}
            >
              Buy Your Window Well Ladders
            </Link>
          </div>
          <div className="w-full md:w-1/2">
            <Link to="/window-well-ladders/">
              <AccessoryLadder className="w-full h-auto" />
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center bg-grey-lighter text-grey-darkest">
          <div className="w-full md:w-1/2 py-16 px-4 md:px-16 leading-tighter">
            <h2 className="mb-4 underline">
              <Link to="/window-well-locks/" className="text-grey-darkest">
                Window Well Locks
              </Link>
            </h2>
            <h3 className="mb-2">
              <Price
                value={
                  lockAccessory?.product?.salePrice ||
                  lockAccessory?.product?.basePrice ||
                  75
                }
              />
            </h3>
            <p>
              Our window well locks system deters people from getting into your
              window wells too easily. Intruders will always look for the
              easiest access point. Don’t make your window wells that easy
              option by leaving them unprotected. Our window well locks are
              designed to easily unlatch from the inside so that you don’t have
              to fumble with it in case you need to get out quick! It can be
              unlocked by adults and children from the inside, while staying
              securely away from anyone on the outside.
            </p>
            <ul>
              <li>
                Easily removed from the inside to allow for escape in an
                emergency.
              </li>
              <li>Added level of security</li>
            </ul>
            <Link
              to="/window-well-locks/"
              className={classNames(
                "block md:inline-block text-sm px-4 py-3 no-underline rounded uppercase shadow",
                styles.button.default
              )}
            >
              Buy Your Window Well Locks
            </Link>
          </div>
          <div className="w-full md:w-1/2">
            <Link to="/window-well-locks/">
              <AccessoryLock className="w-full h-auto" />
            </Link>
          </div>
        </div>
      </div>
      <GoogleReviews />
    </>
  );
}
export default AccessoriesPageTemplate;
