import { Link } from "gatsby";
import React from "react";
import GoogleReviews from "../../components/sections/google-reviews";
import HassleFree from "../../components/sections/hasslefree-images";
import HighQuality from "../../components/sections/highquality-images";
import TrueCustom from "../../components/sections/truecustom-image";
import Seo from "../../components/seo";
import CheckCircle from "../../images/check-circle.svg";
import HouseCircle from "../../images/house-circle.svg";
import StarCircle from "../../images/star-circle.svg";

function WhyUsTemplate({ currentPage, ...props }) {
  let whyImages;

  if (currentPage.slug === "hassle-free") {
    whyImages = <HassleFree />;
  } else if (currentPage.slug === "high-quality") {
    whyImages = <HighQuality />;
  } else if (currentPage.slug === "why-custom-matters") {
    whyImages = <TrueCustom />;
  }

  return (
    <>
      <Seo
        title={currentPage.seo.title}
        description={currentPage.seo.metaDesc}
      />
      <div className="max-w-2xl m-auto flex flex-wrap pb-4 text-center text-grey-darkest text-2xl mt-8">
        <h2 className="px-4 w-full tracking-wide">
          Why {process.env.COMPANY_NAME_SHORT}
        </h2>
      </div>
      <div className="max-w-3xl m-auto pb-12 pt-4 z-20 px-4">
        <div className="bg-white shadow-lg flex flex-wrap rounded text-base">
          <Link
            className={`w-full lg:w-1/3 flex items-center py-6 px-8 no-underline text-grey-darkest rounded-l ${
              currentPage.slug === "why-custom-matters"
                ? `bg-${process.env.THEME_COLOR_PRIMARY}-lighter`
                : ""
            }`}
            to="/why-custom-matters/"
          >
            <img className="px-4" src={HouseCircle} alt="house" />
            <p className="px-4">
              <strong>{process.env.COMPANY_NAME_SHORTER} offers</strong> a
              commitment to a truly custom cover
            </p>
          </Link>
          <Link
            className={`w-full lg:w-1/3 flex items-center py-6 px-8 no-underline text-grey-darkest ${
              currentPage.slug === "high-quality"
                ? `bg-${process.env.THEME_COLOR_PRIMARY}-lighter`
                : ""
            }`}
            to="/high-quality/"
          >
            <img className="px-4" src={StarCircle} alt="house" />
            <p className="px-4">
              <strong>Unrivaled quality</strong> by measuring each individual
              window well
            </p>
          </Link>
          <Link
            className={`w-full lg:w-1/3 flex items-center py-6 px-8 no-underline text-grey-darkest rounded-r ${
              currentPage.slug === "hassle-free"
                ? `bg-${process.env.THEME_COLOR_PRIMARY}-lighter`
                : ""
            }`}
            to="/hassle-free/"
          >
            <img className="px-4" src={CheckCircle} alt="house" />
            <p className="px-4">
              <strong>Hassle-free</strong> with a streamlined order and build
              process
            </p>
          </Link>
        </div>
      </div>

      <div className="max-w-2xl m-auto flex flex-wrap pt-8 pb-16 px-4 text-center text-grey-darker">
        <h1
          className="m-auto"
          dangerouslySetInnerHTML={{
            __html: currentPage.pages.h1Title
              ? currentPage.pages.h1Title
              : currentPage.title,
          }}
        />
      </div>

      <div className="max-w-2xl m-auto flex flex-wrap pb-16">
        <div
          className="w-full md:w-1/2 p-4 lg:px-8 lg:py-0 mb-8 text-grey-darker max-w-2xl wp-content"
          dangerouslySetInnerHTML={{
            __html: currentPage.content,
          }}
        />
        {whyImages}
      </div>

      <GoogleReviews />
    </>
  );
}
export default WhyUsTemplate;
