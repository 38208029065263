import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "../components/seo";
import AboutPageTemplate from "./page-templates/about";
import AccessoriesPageTemplate from "./page-templates/accessories";
import BlogPageTemplate from "./page-templates/blog";
import ContactUsPageTemplate from "./page-templates/contact-us";
import ServiceAreasPageTemplate from "./page-templates/service-areas";
import WhyUsTemplate from "./page-templates/why-us";

const PageTemplate = ({ data }) => {
  const currentPage = data.wpPage;

  if (currentPage.pages.template === "whyUs") {
    return <WhyUsTemplate currentPage={currentPage} />;
  }

  if (currentPage.slug === "window-well-accessories") {
    return <AccessoriesPageTemplate currentPage={currentPage} />;
  }

  if (currentPage.slug === "contact-us") {
    return <ContactUsPageTemplate currentPage={currentPage} />;
  }

  if (currentPage.slug === "about") {
    return <AboutPageTemplate currentPage={currentPage} />;
  }

  if (currentPage.slug === "blog") {
    return <BlogPageTemplate currentPage={currentPage} />;
  }

  if (currentPage.slug === "service-areas") {
    return <ServiceAreasPageTemplate currentPage={currentPage} />;
  }

  return (
    <>
      <Seo
        title={currentPage.seo.title}
        description={currentPage.seo.metaDesc}
      />
      <div className="flex flex-wrap default-page">
        <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
          <h1
            dangerouslySetInnerHTML={{
              __html: currentPage.pages.h1Title
                ? currentPage.pages.h1Title
                : currentPage.title,
            }}
          />
          {currentPage.featuredImage?.node && (
            <GatsbyImage
              alt="Featured image"
              image={
                currentPage.featuredImage.node.localFile.childImageSharp
                  .gatsbyImageData
              }
              style={{ width: "100%", marginBottom: "30px" }}
            />
          )}
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: currentPage.content }}
          />
        </div>
      </div>
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      pages {
        h1Title
        template
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
