import React from "react";
import AccessoryLadder from "../images/accessory-ladder";
import AccessoryEzLift from "../images/accessory-ez-lift";

function HassleFree() {
  return (
    <div className="w-full md:w-1/2">
      <div className="mb-8">
        <AccessoryEzLift className="mb-8 h-64" />
      </div>
      <div>
        <AccessoryLadder className="h-64" />
      </div>
    </div>
  );
}

export default HassleFree;
