import React from "react";
import SteelDown from "../images/steel-down";
import HeadDownWelding from "../images/head-down-welding";

function TrueCustom() {
  return (
    <div className="w-full md:w-1/2">
      <div className="mb-8">
        <SteelDown className="mb-8 h-64" />
      </div>
      <div>
        <HeadDownWelding className="h-64" />
      </div>
    </div>
  );
}

export default TrueCustom;
