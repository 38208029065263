import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const AccessoryEzLift = () => (
  <StaticQuery
    query={graphql`
      {
        placeholderImage: file(relativePath: { eq: "accessory-ez-lift.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <GatsbyImage
        alt="EZ Lift"
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        style={{ width: "100%" }}
      />
    )}
  />
);
export default AccessoryEzLift;
